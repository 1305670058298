
<template>
  <div>
    <h1 v-if="user_level > 1">
      Todos os processos
      <span class="h1-underline"></span>
    </h1>
    <h1 v-else>
      As Minhas Leads
      <span class="h1-underline"></span>
    </h1>

    <v-row class="div__row mt-8 mr-4">
      <v-col cols="6">
        <!-- FILTRO: -->

        <v-select
          :items="estados"
          label="Estado"
          dense
          v-model="estado"
        ></v-select>
      </v-col>
      <!-- <FiltersLeads :estados="estados" @change-estado="updateEstado" /> -->
      <v-col cols="6" class="d-flex justify-end">
        <!-- BTN PARA REFRESH -->
        <v-btn
          color="rgb(246,147,30)"
          class="ma-2 white--text"
          @click="refreshLeads()"
        >
          Refrescar Leads
          <v-icon right dark> mdi-refresh </v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <!-- TABELA: -->
        <v-card>
          <v-card-title class>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Pesquisar"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>

          <v-data-table
            :headers="user_level > 1 ? headersAdmin : headersAgent"
            :items="filteredLeads"
            :search="search"
            :loading="loader"
            loading-text="A carregar..."
          >
            <template v-slot:[`item.editar`]="{ item }">
              <LeadInsertData
                @snackbar="snackbarController"
                :lead="item"
                @update-made="collectLeads"
              />
            </template>
            <template v-slot:[`item.detalhes`]="{ item }">
              <LeadDetail :lead="item" />
            </template>
          </v-data-table>
        </v-card>
        <v-btn
          id="download"
          :loading="loading5"
          :disabled="loading5"
          color="rgb(246, 147, 30)"
          class="ma-2 white--text btn-download"
          fab
          v-if="btnExcelExport"
          @click="exportExcel(filteredLeads_excel)"
        >
          <v-icon dark>mdi-cloud-download</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="snackbar"
      :color="snackbarColor"
      :timeout="5000"
      bottom
    >
      <p class="font-weight-black">
        {{ snackbarMessage }}
      </p>
    </v-snackbar>
  </div>
</template>

<script>
import { json2excel } from "js2excel";
import LeadDetail from "@/components/Lead_Detail";
import LeadInsertData from "@/components/Lead_Insert_Data";

export default {
  components: {
    LeadDetail,
    LeadInsertData,
  },
  name: "Leads",
  data() {
    return {
      snackbarMessage: "",
      snackbarColor: "",
      snackbar: false,

      search: "",
      username: "",
      headersAgent: [
        {
          text: "ID",
          align: "left",
          value: "lead_process_id",
        },
        // { text: 'Data', align: 'center', value: 'dt_created', sortable:true },
        { text: "Nome", align: "center", value: "nome", sortable: true },
        { text: "Email", align: "center", value: "email", sortable: true },
        {
          text: "Telefone",
          align: "center",
          value: "telefone",
          sortable: true,
        },
        { text: "Montante", align: "center", value: "aux_1", sortable: true },
        // { text: 'Prazo', align: 'center', value: 'aux_2', sortable:true },
        {
          text: "Último Contacto",
          align: "center",
          value: "last_interation",
          sortable: true,
        },
        {
          text: "Agendamento",
          align: "center",
          value: "callback_date",
          sortable: true,
        },
        {
          text: "Estado",
          align: "center",
          value: "current_step",
          sortable: true,
        },
        {
          text: "Detalhes",
          align: "center",
          value: "detalhes",
          sortable: true,
        },
        { text: "Editar", align: "center", value: "editar", sortable: false },
      ],

      headersAdmin: [
        {
          text: "ID",
          align: "left",
          value: "lead_process_id",
        },
        {
          text: "Mediador",
          align: "center",
          value: "username",
          sortable: true,
        },
        { text: "Nome", align: "center", value: "nome", sortable: true },
        { text: "Email", align: "center", value: "email", sortable: true },
        {
          text: "Telefone",
          align: "center",
          value: "telefone",
          sortable: true,
        },
        { text: "Montante", align: "center", value: "aux_1", sortable: true },
        // { text: 'Prazo', align: 'center', value: 'aux_2', sortable:true },
        {
          text: "Último Contacto",
          align: "center",
          value: "last_interation",
          sortable: true,
        },
        {
          text: "Agendamento",
          align: "center",
          value: "callback_date",
          sortable: true,
        },
        {
          text: "Estado",
          align: "center",
          value: "current_step",
          sortable: true,
        },
        {
          text: "Detalhes",
          align: "center",
          value: "detalhes",
          sortable: true,
        },
        { text: "Editar", align: "center", value: "editar", sortable: false },
      ],
      items: [],
      items_excel: [],
      loader: true,
      loading: false,
      loading2: false,
      loading3: false,
      loading4: false,
      loading5: false,

      user_level: null,
      estados: [],
      estado: "All",
    };
  },
  computed: {
    filteredLeads() {
      if (this.estado == "All") {
        return this.items;
      } else {
        let map = this.items.filter((e) => {
          return e.current_step == this.estado;
        });

        return map;
      }
    },
    filteredLeads_excel() {
      if (this.estado == "All") {
        return this.items_excel;
      } else {
        let map = this.items_excel.filter((e) => {
          return e.current_step == this.estado;
        });

        return map;
      }
    },
    btnExcelExport() {
      return this.$store.state.btnExcelExport;
    },
  },
  methods: {
    snackbarController(res) {
      this.snackbar = true;

      if (res.resultado == "OK") {
        this.snackbarMessage = "As alterações foram guardadas com sucesso!";
        this.snackbarColor = "success";
      } else {
        this.snackbarMessage = res.details;
        this.snackbarColor = "error";
      }
    },
    changeVisualBtn: function () {
      this.$store.commit("SET_BTN_EXPORT_EXCEL", false);
    },
    exportExcel: async function (data) {
      try {
        json2excel({
          data,
          name: `Export_All_Leads`,
          formateDate: "yyyy-mm-dd",
        });
      } catch (e) {
        alert("export error: " + e);
      }
    },
    collectLeads_excel: async function () {
      if (this.user_level > 1) {
        await this.$store.dispatch("collect_leads_enabled_excel");
      } else {
        await this.$store.dispatch("my_leads_in_process_new", this.username);
      }

      if (this.$store.state.consultores.length == 0) {
        await this.$store.dispatch("collect_consultores");
      }

      const dados = this.$store.state.leads_enabled_excel;

      const d = Object.keys(dados).map((i) => dados[i]);

      //Set all table items:
      this.items_excel = d;

      this.estados = [...new Set(d.map((item) => item.current_step))];
      this.estados.push("All");
      this.estados = this.estados.sort();

      this.loader = false;
    },
    collectLeads: async function () {
      if (this.user_level > 1) {
        await this.$store.dispatch("collect_leads_enabled");
      } else {
        await this.$store.dispatch("my_leads_in_process_new", this.username);
      }

      if (this.$store.state.consultores.length == 0) {
        await this.$store.dispatch("collect_consultores");
      }

      const dados = this.$store.state.leads_enabled;

      const d = Object.keys(dados).map((i) => dados[i]);

      //Set all table items:
      this.items = d;

      this.estados = [...new Set(d.map((item) => item.current_step))];
      this.estados.push("All");
      this.estados = this.estados.sort();

      this.loader = false;
    },
    refreshLeads: async function () {
      await this.$store.dispatch("refresh_leads");

      this.loader = true;

      this.items = [];

      await this.collectLeads();
    },
  },
  mounted: function () {
    let isLogged = window.sessionStorage.getItem("user_id");
    const result = isLogged == null ? false : true;

    if (!result) {
      this.$router.push("login");
    } else {
      this.username = window.sessionStorage.getItem("username");
      this.user_level = window.sessionStorage.getItem("user_level");
    }

    this.collectLeads();
    this.collectLeads_excel();
  },
};
</script>

<style scoped>
#download {
  z-index: 999;
}
h1 {
  margin-left: 296px;
  color: black;
  font-size: 20px;
  text-transform: uppercase;
  margin-top: 50px;
  position: relative;
  display: block;
}
.h1-underline {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 60px;
  height: 3px;
  background-color: black;
  margin-bottom: -6px;
}
.v-card {
  margin: 10px 40px 50px 296px;
  padding: 40px;
  padding-top: 10px;
}
.v-card__title {
  margin-bottom: 30px;
}

.btn-download {
  margin-left: 330px !important;
  margin-top: -120px !important;
  width: 70px;
  height: 70px;
  background-color: rgb(246, 147, 30) !important;
}
.btn-download i {
  font-size: 30px !important;
}

.div__row {
  margin-left: 296px;
}

</style>
   